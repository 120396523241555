const libraryVersion = '%webpack_version%';

const METHODS = {
  IS_WRAPPER_ACTIVE: 'IS_WRAPPER_ACTIVE',
  GET_OPERATOR_INFORMATION: 'GET_OPERATOR_INFORMATION',
  GET_SCREEN_SIZE: 'GET_SCREEN_SIZE'
};

const sendMessageToWrapper = ({action, data}) => {
  const message = {action, data};
  window.parent.postMessage(JSON.stringify(message), '*');
};

const callbacks = {};
const promises = {};

const calledByGame = {
  gameLoadingStarted: () => sendMessageToWrapper({action: 'gameLoadingStarted'}),

  loadProgressUpdate: (percent) => {
    sendMessageToWrapper({
      action: 'loadProgressUpdate',
      data: {
        percent
      }
    });
  },

  gameLoadingEnded: () => sendMessageToWrapper({action: 'gameLoadingEnded'}),

  winningsUpdate: (winnings) => {
    sendMessageToWrapper({
      action: 'winningsUpdate',
      data: {
        winnings
      }
    });
  },

  stakeUpdate: (stake) => {
    sendMessageToWrapper({
      action: 'stakeUpdate',
      data: {
        stake
      }
    });
  },

  balancesUpdate: (balances, balanceFudge) => {
    let message = {
      action: 'balancesUpdate',
      data: {
        balances
      }
    };
    if (balanceFudge) {
      message.data.balanceFudge = balanceFudge;
    }
    sendMessageToWrapper(message);
  },

  setBusy: () => sendMessageToWrapper({action: 'gameRoundStarted'}),
  gameRoundStarted: (timestamp) => {
    let message = {
      action: 'gameRoundStarted'
    };
    if (timestamp) {
      message.data = {
        timestamp
      };
    }
    sendMessageToWrapper(message);
  },

  gameRoundResponseReceived: (timestamp) => {
    sendMessageToWrapper({
      action: 'gameRoundResponseReceived',
      data: {
        timestamp
      }
    });
  },

  setIdle: () => sendMessageToWrapper({action: 'gameRoundEnded'}),
  gameRoundEnded: (timestamp) => {
    let message = {
      action: 'gameRoundEnded'
    };
    if (timestamp) {
      message.data = {
        timestamp
      };
    }
    sendMessageToWrapper(message);
  },

  autoplayModeStarted: (numberOfRounds) => {
    sendMessageToWrapper({
      action: 'autoplayModeStarted',
      data: {
        numberOfRounds
      }
    });
  },
  autoplayModeEnded: (reason) => {
    sendMessageToWrapper({
      action: 'autoplayModeEnded',
      data: {
        reason
      }
    });
  },

  disableAutoplay: () => sendMessageToWrapper({action: 'disableAutoplay'}),
  
  enableAutoplay: () => sendMessageToWrapper({action: 'enableAutoplay'}),

  stopAutoplay: () => sendMessageToWrapper({action: 'stopAutoplay'}),

  enableFeatureMode: (featureId) => {
    let message = {
      action: 'enableFeatureMode'
    };
    if (featureId) {
      message.data = {
        featureId
      };
    }
    sendMessageToWrapper(message);
  },

  disableFeatureMode: (featureId) => {
    let message = {
      action: 'disableFeatureMode'
    };
    if (featureId) {
      message.data = {
        featureId
      };
    }
    sendMessageToWrapper(message);
  },

  reloadGame: () => sendMessageToWrapper({action: 'reloadGame'}),

  exitGame: () => sendMessageToWrapper({action: 'exitGame'}),

  gotoMoneyMode: () => sendMessageToWrapper({action: 'gotoMoneyMode'}),

  gotoDepositPage: () => sendMessageToWrapper({action: 'gotoDepositPage'}),

  gotoAccountsPage: () => sendMessageToWrapper({action: 'gotoAccountsPage'}),

  enableAudio: (type) => {
    let message = {
      action: 'enableAudio'
    };
    if (type) {
      message.data = {
        type
      };
    }
    sendMessageToWrapper(message);
  },

  disableAudio: (type) => {
    let message = {
      action: 'disableAudio'
    };
    if (type) {
      message.data = {
        type
      };
    }
    sendMessageToWrapper(message);
  },

  gameScreenOrientationUpdate: (orientation) => {
    sendMessageToWrapper({
      action: 'gameScreenOrientationUpdate',
      data: {
        orientation
      }
    });
  },

  menuDialogueOpened: () => sendMessageToWrapper({action: 'menuDialogueOpened'}),

  menuDialogueClosed: () => sendMessageToWrapper({action: 'menuDialogueClosed'}),

  betDialogueOpened: () => sendMessageToWrapper({action: 'betDialogueOpened'}),

  betDialogueClosed: () => sendMessageToWrapper({action: 'betDialogueClosed'}),

  autospinDialogueOpened: () => sendMessageToWrapper({action: 'autospinDialogueOpened'}),

  autospinDialogueClosed: () => sendMessageToWrapper({action: 'autospinDialogueClosed'}),

  paytableDialogueOpened: () => sendMessageToWrapper({action: 'paytableDialogueOpened'}),

  paytableDialogueClosed: () => sendMessageToWrapper({action: 'paytableDialogueClosed'}),

  insufficientFunds: () => sendMessageToWrapper({action: 'insufficientFunds'}),

  gamePopupShown: () => sendMessageToWrapper({action: 'gamePopupShown'}),

  gamePopupHidden: () => sendMessageToWrapper({action: 'gamePopupHidden'}),

  bonusGameRecovering: () => sendMessageToWrapper({action: 'bonusGameRecovering'}),

  gameError: (errorInfo) => {
    sendMessageToWrapper({
      action: 'gameError',
      data: {
        errorInfo
      }
    });
  },

  openGameHistory: (roundId) => {
    sendMessageToWrapper({
      action: 'openGameHistory',
      data: {
        roundId
      }
    });
  }
};

const calledByGameWithResponse = {
  isWrapperActive: (callback) => {
    callbacks[METHODS.IS_WRAPPER_ACTIVE] = callback;
    sendMessageToWrapper({action: 'isWrapperActive'});
    return new Promise((resolve) => {
      promises[METHODS.IS_WRAPPER_ACTIVE] = resolve;
    });
  },
  getOperatorInformation: (callback) => {
    callbacks[METHODS.GET_OPERATOR_INFORMATION] = callback;
    sendMessageToWrapper({action: 'getOperatorInformation'});
    return new Promise((resolve) => {
      promises[METHODS.GET_OPERATOR_INFORMATION] = resolve;
    });
  },
  getReplayInformation: (callback) => {
    callbacks[METHODS.GET_OPERATOR_INFORMATION] = callback;
    sendMessageToWrapper({action: 'getReplayInformation'});
    return new Promise((resolve) => {
      promises[METHODS.GET_OPERATOR_INFORMATION] = resolve;
    });
  },
  getScreenSize: (callback) => {
    callbacks[METHODS.GET_SCREEN_SIZE] = callback;
    sendMessageToWrapper({action: 'getScreenSize'});
    return new Promise((resolve) => {
      promises[METHODS.GET_SCREEN_SIZE] = resolve;
    });
  }
};

const calledByWrapper = {
  // This part is here just for the consistency with the documentation,
  // as wrapper-client sends message to game via direct postMessage,
  // not via Wrapper API
  pauseGame: () => {},
  resumeGame: () => {},
  balancesHasChanged: (balances) => {},
  enableGameAudio: () => {},
  disableGameAudio: () => {},
  openPaytable: () => {},
  openHelp: () => {},
  openPreferences: () => {},
  openAbout: () => {},
  force: (data) => {},
  freeRoundsCounterUpdate: () => {},
  disableAutoplay: () => {},
  enableAutoplay: () => {},
  stopAutoplay: () => {},
  majorPrize: () => {},
};

const gameWrapper = (function() {
  this.silent = true;
  console.log(`%c [Gameiom Wrapper Library] v${libraryVersion} `, 'background: #e66700; color: white;');
  const gameWrapper = {
    ...calledByGame,
    ...calledByGameWithResponse,
    ...calledByWrapper
  };

  window.addEventListener('message', (message) => {
    let _message;
    try {
      _message = JSON.parse(message.data);
      if (!this.silent) {
        console.log(`%c [Gameiom Wrapper Library]`, 'background: #e66700; color: white;', 'Message from Wrapper', _message);
      }
    } catch (e) {
      return;
    }
    const action = _message.action;
    const data = _message.data;

    switch (action) {
      case 'isWrapperActive': {
        if (callbacks[METHODS.IS_WRAPPER_ACTIVE]) {
          callbacks[METHODS.IS_WRAPPER_ACTIVE](data);
          delete callbacks[METHODS.IS_WRAPPER_ACTIVE];
        }
        if (promises[METHODS.IS_WRAPPER_ACTIVE]) {
          promises[METHODS.IS_WRAPPER_ACTIVE](data);
          delete promises[METHODS.IS_WRAPPER_ACTIVE];
        }
        break;
      }
      case 'getOperatorInformation': {
        if (callbacks[METHODS.GET_OPERATOR_INFORMATION]) {
          callbacks[METHODS.GET_OPERATOR_INFORMATION](data);
          delete callbacks[METHODS.GET_OPERATOR_INFORMATION];
        }
        if (promises[METHODS.GET_OPERATOR_INFORMATION]) {
          promises[METHODS.GET_OPERATOR_INFORMATION](data);
          delete promises[METHODS.GET_OPERATOR_INFORMATION];
        }
        break;
      }
      case 'getScreenSize': {
        if (callbacks[METHODS.GET_SCREEN_SIZE]) {
          callbacks[METHODS.GET_SCREEN_SIZE](data);
          delete callbacks[METHODS.GET_SCREEN_SIZE];
        }
        if (promises[METHODS.GET_SCREEN_SIZE]) {
          promises[METHODS.GET_SCREEN_SIZE](data);
          delete promises[METHODS.GET_SCREEN_SIZE];
        }
        break;
      }
      case 'balancesHasChanged': {
        gameWrapper.balancesHasChanged(data);
        break;
      }
      case 'pauseGame': {
        gameWrapper.pauseGame();
        break;
      }
      case 'resumeGame': {
        gameWrapper.resumeGame();
        break;
      }
      case 'enableAudio': {
        gameWrapper.enableGameAudio();
        break;
      }
      case 'disableAudio': {
        gameWrapper.disableGameAudio();
        break;
      }
      case 'wrapperActivityChanged': {
        if (data['active'] === true) {
          gameWrapper.pauseGame();
        } else if (data['active'] === false) {
          gameWrapper.resumeGame();
        }
        break;
      }
    }

    // Deprecated feature

    if (_message['gameWrapper'] !== undefined) {
      if (_message['gameWrapper'] === true) {
        gameWrapper.pauseGame();
      }
      if (_message['gameWrapper'] === false) {
        gameWrapper.resumeGame();
      }
    }

  }, false);

  return gameWrapper;
})();



window.gameWrapper = gameWrapper;
